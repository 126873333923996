import React, { useState, ChangeEvent } from 'react';
import { Heading } from '@chanzuckerberg/eds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

export default function Root() {
  const [urlInput, setUrlInput] = useState('');
  const [clickedUrls, setClickedUrls] = useState<string[]>([]);

  const handleUrlInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUrlInput(e.target.value);
  };

  const handleUrlSubmit = () => {
    setClickedUrls((prevUrls) => [...prevUrls, urlInput]);
    setUrlInput('');
  };

  const handlePostRequest = async () => {
    if (clickedUrls.length === 0) {
      // No URLs entered
      return;
    }

    try {
      const response = await axios.post('http://copicatsecret-production.up.railway.app/check-urls', {
        urls: clickedUrls,
      });
      console.log(response.data); // Success message from the backend
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <main className="p-5">
        <Heading size="h2">Copicat</Heading>

        <div className="input-wrapper">
          <input
            type="text"
            value={urlInput}
            onChange={handleUrlInputChange}
            placeholder="Enter URL"
          />
          <FontAwesomeIcon
            icon={faCheck}
            className="submit-icon"
            onClick={handleUrlSubmit}
          />
        </div>

        {clickedUrls.length > 0 && (
          <div className="clicked-urls">
            <h3>Submitted URLs:</h3>
            <ul>
              {clickedUrls.map((url, index) => (
                <li key={index}>{url}</li>
              ))}
            </ul>
          </div>
        )}

        <button
          onClick={handlePostRequest}
          style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            marginTop: '10px',
          }}
        >
          Send POST Request
        </button>
      </main>
    </>
  );
}
